<template>
  <el-dialog title="话题详情" :visible.sync="openDialog" width="500px" :show-close="true"
    class="dialog-vertical" @close="cancel('topicConfig')" close-on-click-modal>
    <el-form size="medium" :model="topicData" :rules="rules" ref="topicConfig" label-width="90px">
      <el-form-item label="话题名称：" prop="name">{{topicData.name}}</el-form-item>
      <el-form-item label="选择小组：" prop="with_groups">
        <template v-for="(t, tI) in topicData.with_groups">
          <template v-if="tI > 0">、</template>
          <template v-if="groupOptions.find(g => g.id === t)">
            {{groupOptions.find(g => g.id === t).name}}
          </template>
          <template v-else>{{'' | placeholder}}</template>
        </template>
        <template v-if="!topicData.with_groups || !topicData.with_groups.length">{{'' | placeholder}}</template>
      </el-form-item>
      <el-form-item label="话题简介：" prop="description">{{topicData.description | placeholder}}
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { groupOptions } from "../../../community-admin/api/group/group-list";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    Data: {
      type: Object,
    },
    groupID: {
      type: [String, Number],
      default: 0,
    },
    // 弹窗
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      rules: {

      },
      categoryOptions: [],
      groupOptions: [],
      saveLoading: false,
      categoryOptionsLoading: false,
      groupOptionsLoading: false,
      DefaultImg:
        window.serverConfig.VUE_APP_ADMINURL +
        "/cyc/images/admin/company_logo.png",
    };
  },
  watch: {
    dialogOpen(val) {
      if (val) {
        this.$refs.topicConfig.resetFields();
        this.$nextTick(() => {
          this.topicData = { ...this.Data };
        });
      }
    },
  },
  created() {
    this.categoryOptionsLoading = true;
    this.groupOptionsLoading = true;
  },
  mounted() {
    groupOptions()
      .then((res) => {
        this.groupOptions = res.data;
        this.groupOptionsLoading = false;
      })
      .catch((err) => {
        this.groupOptionsLoading = false;
      });
  },
  methods: {
    // 选择小组更改
    withGroupsChange(val) {
      if (
        this.$route.params.id &&
        !this.topicData.with_groups.includes(this.$route.params.id)
      ) {
        this.topicData.with_groups.unshift(this.$route.params.id);
        this.$message.info("如要删除，请打开选项取消勾选");
      }
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    topicData: {
      get() {
        return this.Data;
      },
      set(val) {
        this.$emit("update: updateData", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}

.dialog-vertical {
  ::v-deep.el-input--medium .el-input__inner {
    height: 36px !important;
  }
}

.info-title {
  text-align: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 30px;
}
</style>
