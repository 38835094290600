<!--
 * @Author: dongjia
 * @Date: 2021-09-29 16:14:06
 * @LastEditTime: 2021-09-29 17:01:03
 * @LastEditors: aleaner
 * @Description: 同步资源列表弹窗
 * @FilePath: \gov\components\resource-library\index\SyncResourceDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="同步至资源列表" :visible.sync="show" width="500px" class="dialog-vertical check-view-dialog">
    <div class="content">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="80px" size="medium"
        class="medium-form">
        <el-form-item label="内容类型：" prop="is_demand">
          <el-select v-model="form.is_demand" placeholder="请选择内容类型">
            <el-option v-for="item in demandOptions" :label="item.name" :value="item.id" :key="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择分类：" prop="category_id">
          <el-select v-model="form.category_id" :disabled="!form.is_demand === '' || gettingCategory"
            :placeholder="!form.is_demand === ''?'请先选择内容类型':gettingCategory?'加载中...':'请选择分类'">
            <el-option v-for="item in categoryOptions" :label="item.name" :value="item.id" :key="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button size="medium" @click="show=false">取消
      </el-button>
      <el-button type="primary" size="medium" :loading="saveLoading" @click="onSave('ruleForm')">确定
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import {
  allCategory,
  createCircleContents,
} from "../../../api/resource-library/index";
export default {
  model: {
    prop: "open",
    event: "handleOpen",
  },
  props: {
    //是否打开
    open: {
      type: Boolean,
      required: true,
    },
    //资源id数组
    resourceIds: {
      type: Array,
      required: true,
    },
    //完成后的回调
    complete: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        is_demand: "",
        category_id: "",
      },
      saveLoading: false,
      rules: {
        is_demand: [
          { required: true, message: "请选择内容类型", trigger: "change" },
        ],
        category_id: [
          { required: true, message: "请选择分类", trigger: "change" },
        ],
      },
      demandOptions: [
        { name: "供给", id: 0 },
        { name: "需求", id: 1 },
      ],
      gettingCategory: false,
      categoryOptions: [],
    };
  },
  watch: {
    "form.is_demand"(val) {
      if (val !== "") {
        this.gettingCategory = true;
        this.form.category_id = "";
        allCategory({ is_demand: val })
          .then((res) => {
            const { data } = res;
            this.categoryOptions = data;
            this.gettingCategory = false;
          })
          .catch(() => {
            this.gettingCategory = false;
          });
      }
    },
    show(val) {
      if (!val) {
        this.$refs.ruleForm.resetFields();
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("handleOpen", val);
      },
    },
  },
  methods: {
    //保存
    onSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          const postData = {
            ids: this.resourceIds,
            is_demand: this.form.is_demand,
            category_id: this.form.category_id,
          };
          createCircleContents(postData)
            .then((res) => {
              const { msg } = res;
              this.$message.success(msg);
              this.saveLoading = false;
              this.show = false;
              this.complete();
            })
            .catch(() => {
              this.saveLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
