<!--
 * @Author: mulingyuer
 * @Date: 2021-09-13 10:27:51
 * @LastEditTime: 2021-09-15 16:49:05
 * @LastEditors: mulingyuer
 * @Description: 资源库筛选
 * @FilePath: \gov-admin-vue\src\modules\data-list\components\resource-library\index\Filter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <LayoutFilter :on-reset="onReset" :on-filter="onFilter" labelWidth="90px">
    <el-form class="filter-from" :model="form" :rules="rules" size="medium" ref="ruleForm" label-width="90px">
      <div class="filter-item">
        <DatePeriodPicker label="发布时间：" :start-time.sync="form.post_start_time" start-prop="post_start_time"
          :end-time.sync="form.post_end_time" end-prop="post_end_time" />
      </div>
      <div class="filter-item">
        <el-form-item label="搜索内容：" prop="content">
          <el-input v-model="form.content" placeholder="请输入搜索内容"></el-input>
        </el-form-item>
        <el-form-item label="内容类型：" prop="is_demand">
          <el-select v-model="form.is_demand" placeholder="请选择内容类型">
            <el-option v-for="op in isDemandArr" :key="op.id" :label="op.name" :value="op.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容分类：" prop="category_name">
          <el-select v-model="form.category_name" placeholder="请选择内容分类">
            <el-option v-for="op in categoryIdArr" :key="op.value" :label="op.name"
              :value="op.value">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="发布人：" prop="author_name">
          <el-input v-model="form.author_name" placeholder="请输入发布人"></el-input>
        </el-form-item>
        <el-form-item label="内容付费：" prop="pay_type">
          <el-select v-model="form.pay_type" placeholder="请选择内容付费">
            <el-option v-for="op in payTypeArr" :key="op.id" :label="op.name" :value="op.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属商协会：" prop="project_id">
          <el-select v-model="form.project_id" placeholder="请选择所属商协会" @change="onProjectIdChange">
            <el-option v-for="op in projectIdArr" :key="op.project_id" :label="op.name"
              :value="op.project_id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { BAList } from "../../../api/default";
import { categoriesList } from "../../../api/resource-library/index";
export default {
  props: {
    //筛选回调
    filterChange: {
      required: true,
      type: Function,
    },
    //导出要用的数据
    exportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        post_start_time: -1, //发布开始时间
        post_end_time: -1, //发布结束时间
        content: "", //搜索内容
        is_demand: -1, // 内容类型
        category_id: -1, // 内容分类
        category_name: '',
        author_name: "", //发布人
        pay_type: -1, // 内容付费
        project_id: "", //所属商协会
      },
      rules: {},
      //所属商协会选项
      projectIdArr: [],
      //内容类型选项数据
      isDemandArr: [
        { name: "全部", id: -1 },
        { name: "供给", id: 0 },
        { name: "需求", id: 1 },
      ],
      //内容分类选项数据
      categoryIdArr: [{ category_id: -1, name: "全部" }],
      //内容付费选项数据
      payTypeArr: [
        { name: "全部", id: -1 },
        { name: "无", id: 0 },
        { name: "付费", id: 1 },
        { name: "积分", id: 2 },
      ],
    };
  },
  computed: {
    //商协会id
    projectId({ $route }) {
      return $route.query.projectId;
    },
  },
  methods: {
    //重置表单
    onReset() {
      this.$refs["ruleForm"].resetFields();
      this.onFilter();
    },
    //筛选
    onFilter() {
      return this.filterChange(Object.assign({}, this.form));
    },
    //导出
    onExport() {
      const postData = { ...this.exportData, ...this.form };
      // console.log("提交的数据", postData);
      // return orderDocument(postData).catch(() => {});
    },
    //获取商协会选项数据
    getProjectIdArr() {
      BAList()
        .then((res) => {
          const { data } = res;
          this.projectIdArr = data;
        })
        .catch(() => {});
    },
    //获取分类选项数据
    getCategoryIdArr() {
      const postData = { project_id: this.form.project_id };
      categoriesList(postData)
        .then((res) => {
          const { data } = res;
          this.categoryIdArr.push(...data);
        })
        .catch(() => {});
    },
    //商协会选项变更
    onProjectIdChange() {
      this.form.category_id = -1; //重置分类
      this.getCategoryIdArr();
    },
  },
  created() {
    if (typeof this.projectId !== "undefined") {
      this.form.project_id = this.projectId;
    }
    this.getProjectIdArr();
    this.getCategoryIdArr();
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>

<style lang="scss" scoped>
</style>
