<!--
 * @Author: mulingyuer
 * @Date: 2021-09-15 15:50:47
 * @LastEditTime: 2021-09-26 18:14:32
 * @LastEditors: aleaner
 * @Description: 企业库
 * @FilePath: \gov\views\enterprise-library\index.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="table-page enterprise-library">
    <div class="head">
      <ListFilter :filterChange="onFilter" :exportData="postData" />
    </div>
    <div class="content" v-loading="loading">
      <el-table class="thead-light" :data="tbody" stripe style="width: 100%">
        <el-table-column v-for="th in thead" :key="th.prop" :label="th.label" :prop="th.prop"
          :width="th.width" :min-width="th.minWidth" :sortable="th.sortable" :fixed="th.fixed"
          :show-overflow-tooltip="th.tooltip">
          <template slot-scope="{ row }">
            <!-- 操作 -->
            <template v-if="th.prop === 'operate'">
              <el-button type="text" size="mini" @click="$message.info('开发中...')">查看</el-button>
            </template>
            <!-- 其他 -->
            <span v-else>
              {{ row[th.prop]|placeholder }}{{th.suffix?th.suffix:""}}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
    </div>
  </div>
</template>

<script>
import ListFilter from "../../components/enterprise-library/index/Filter.vue";
import Pagination from "@/base/components/Default/Pagination";
import { list } from "../../api/enterprise-library/index";
export default {
  data() {
    return {
      loading: false, //加载中
      postData: {},
      thead: [
        { label: "ID", prop: "id", minWidth: 120 },
        { label: "企业名称", prop: "name", minWidth: 180, tooltip: true },
        { label: "行业类型", prop: "category_name", minWidth: 120 },
        { label: "企业法人", prop: "oper_name", minWidth: 120 },
        { label: "上市公司", prop: "stock_status", minWidth: 120 },
        { label: "成立时间", prop: "start_date", minWidth: 180 },
        { label: "员工人数", prop: "worker_sum", minWidth: 120 },
        { label: "企业地址", prop: "address", minWidth: 180, tooltip: true },
        { label: "认证状态", prop: "ident_text", minWidth: 120 },
        { label: "管理员", prop: "admin_name", minWidth: 120 },
        { label: "被举报记录", prop: "reports_count", minWidth: 120 },
        {
          label: "所属商协会",
          prop: "project_name",
          minWidth: 120,
          tooltip: true,
        },
        { label: "操作", prop: "operate", minWidth: 120, fixed: "right" },
      ],
      tbody: [],
      pageData: {},
    };
  },
  computed: {
    //商协会id
    projectId({ $route }) {
      return $route.query.projectId;
    },
  },
  methods: {
    //获取列表数据
    getList(pageData) {
      this.loading = true;
      return list(pageData || this.postData)
        .then((res) => {
          const { data } = res;
          this.pageData = data;
          this.tbody = data.data;

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //筛选事件
    onFilter(filterObj) {
      Object.assign(this.postData, filterObj, { page: 1 });
      return this.getList();
    },
    //分页变化事件
    changPage(pageObj) {
      Object.assign(this.postData, pageObj);
      this.getList();
    },
  },
  created() {
    if (typeof this.projectId !== "undefined") {
      this.postData.project_id = this.projectId;
    }
    this.getList();
  },
  components: {
    ListFilter,
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
</style>
