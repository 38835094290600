/*
 * @Author: mulingyuer
 * @Date: 2021-09-15 16:10:42
 * @LastEditTime: 2021-09-23 15:01:39
 * @LastEditors: aleaner
 * @Description: 企业库
 * @FilePath: \gov\api\enterprise-library\index.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";


//行业分类选项数据
export const categoriesList = data => {
  return api({
    url: "/admin/gov/company/categories",
    method: "post",
    data
  })
}


//成立时间选项数据
export const startDates = data => {
  return api({
    url: "/admin/gov/company/startDates",
    method: "post",
    data
  })
}


//企业库列表
export const list = data => {
  return api({
    url: "/admin/gov/company/index",
    method: "post",
    data
  })
}