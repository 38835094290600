<!--
 * @Author: dongjia
 * @Date: 2021-09-29 15:32:39
 * @LastEditTime: 2021-09-29 17:00:30
 * @LastEditors: aleaner
 * @Description: 同步文章列表弹窗
 * @FilePath: \gov\components\news-library\index\SyncArticleDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="同步至文章列表" :visible.sync="show" width="500px" class="dialog-vertical check-view-dialog">
    <div class="content">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="80px" size="medium"
        class="medium-form">
        <el-form-item label="选择分类：" prop="category_id">
          <el-cascader v-model="form.category_id" :options="categoryOptions" :props="casProps" ref="levels"
            :show-all-levels="false" :placeholder="gettingCategory?'加载中...':'请选择分类'" clearable
            :disabled="gettingCategory">
          </el-cascader>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button size="medium" @click="show=false">取消
      </el-button>
      <el-button type="primary" size="medium" :loading="saveLoading" @click="onSave('ruleForm')">确定
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import {
  allClassifyData,
  createArticles,
} from "../../../api/news-library/index";
export default {
  model: {
    prop: "open",
    event: "handleOpen",
  },
  props: {
    //是否打开
    open: {
      type: Boolean,
      required: true,
    },
    //文章id数组
    articleIds: {
      type: Array,
      required: true,
    },
    //完成后的回调
    complete: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        category_id: "",
      },
      saveLoading: false,
      rules: {
        category_id: [
          { required: true, message: "请选择分类", trigger: "change" },
        ],
      },
      // 级联属性
      casProps: {
        children: "_children",
        label: "name",
        value: "id",
        expandTrigger: "hover",
        emitPath: false,
        checkStrictly: true,
      },
      gettingCategory: false,
      categoryOptions: [],
    };
  },
  watch: {
    show(val) {
      if (val) {
        this.gettingCategory = true;
        allClassifyData()
          .then((res) => {
            const { data } = res;
            this.categoryOptions = data;
            this.gettingCategory = false;
          })
          .catch((err) => {
            this.gettingCategory = false;
          });
      } else {
        this.$refs.ruleForm.resetFields();
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("handleOpen", val);
      },
    },
  },
  methods: {
    //保存
    onSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          const postData = {
            ids: this.articleIds,
            category_id: this.form.category_id,
          };
          createArticles(postData)
            .then((res) => {
              const { msg } = res;
              this.$message.success(msg);
              this.saveLoading = false;
              this.show = false;
              this.complete();
            })
            .catch(() => {
              this.saveLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
