<!--
 * @Author: mulingyuer
 * @Date: 2021-09-13 10:27:51
 * @LastEditTime: 2021-10-19 11:08:38
 * @LastEditors: aleaner
 * @Description: 会员库筛选
 * @FilePath: \gov\components\member-library\index\Filter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <LayoutFilter :on-reset="onReset" :on-filter="onFilter" labelWidth="90px">
    <el-form class="filter-from" :model="form" :rules="rules" size="medium" ref="ruleForm" label-width="90px">

      <div class="filter-item">
        <el-form-item label="会员名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入会员名称"></el-input>
        </el-form-item>
        <el-form-item label="性别：" prop="gender">
          <el-select v-model="form.gender" placeholder="请选择性别">
            <el-option v-for="op in genderArr" :key="op.id" :label="op.name" :value="op.id">
            </el-option>
          </el-select>
        </el-form-item>
<!--        <el-form-item label="会内职务：" prop="position_names">-->
<!--          <el-cascader v-model="form.position_names" :options="positionIdsArr" :props="positionIdsProps"-->
<!--            placeholder="请选择会内职务">-->
<!--          </el-cascader>-->
<!--        </el-form-item>-->
      </div>
      <div class="filter-item">
        <el-form-item label="会费状态：" prop="fee_status">
          <el-select v-model="form.fee_status" placeholder="请选择会费状态">
            <el-option v-for="op in feeStatusArr" :key="op.id" :label="op.name" :value="op.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号码：" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入手机号码"></el-input>
        </el-form-item>
        <el-form-item label="所属商协会：" prop="project_id">
          <el-select v-model="form.project_id" placeholder="请选择所属商协会" @change="onProjectIdChange">
            <el-option v-for="op in projectIdArr" :key="op.project_id" :label="op.name"
              :value="op.project_id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="入会时间：" :start-time.sync="form.join_start_time" start-prop="join_start_time"
          :end-time.sync="form.join_end_time" end-prop="join_end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
// import { positionList } from "../../../api/member-library/index";
import { BAList } from "../../../api/default";
export default {
  props: {
    //筛选回调
    filterChange: {
      required: true,
      type: Function,
    },
    //导出要用的数据
    exportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        join_start_time: -1, //入会开始时间
        join_end_time: -1, //入会结束时间
        name: "", //会员名称
        gender: -1, //性别
        position_names: [], //会内职务
        fee_status: -1, //会费状态
        phone: "", //手机号
        project_id: "", //所属商协会
      },
      rules: {},
      //性别选项数据
      genderArr: [
        { name: "全部", id: -1 },
        { name: "男", id: 1 },
        { name: "女", id: 2 },
      ],
      //会内职务数据
      positionIdsArr: [],
      positionIdsProps: {
        expandTrigger: "hover",
        children: "positions",
        label: "name",
        value: "value",
        emitPath: true,
      },
      //会费状态
      feeStatusArr: [
        { name: "全部", id: -1 },
        { name: "未缴费", id: 0 },
        { name: "已缴费", id: 1 },
      ],
      //所属商协会选项
      projectIdArr: [],
    };
  },
  computed: {
    //商协会id
    projectId({ $route }) {
      return $route.query.projectId;
    },
  },
  methods: {
    //重置表单
    onReset() {
      this.$refs["ruleForm"].resetFields();
      this.onFilter();
    },
    //筛选
    onFilter() {
      return this.filterChange(Object.assign({}, this.form));
    },
    //导出
    onExport() {
      const postData = { ...this.exportData, ...this.form };
      // console.log("提交的数据", postData);
      // return orderDocument(postData).catch(() => {});
    },
    //获取会内职务选项数据
    // getPositionIdsArr() {
    //   const postData = { project_id: this.form.project_id };
    //   positionList(postData)
    //     .then((res) => {
    //       const { data } = res;
    //       this.positionIdsArr = data;
    //     })
    //     .catch(() => {});
    // },
    //获取商协会选项数据
    getProjectIdArr() {
      BAList()
        .then((res) => {
          const { data } = res;
          this.projectIdArr = data;
        })
        .catch(() => {});
    },
    //商协会选项变更
    onProjectIdChange() {
      this.form.position_names = []; //会内职务清空
      // this.getPositionIdsArr();
    },
  },
  created() {
    if (typeof this.projectId !== "undefined") {
      this.form.project_id = this.projectId;
    }
    // this.getPositionIdsArr();
    this.getProjectIdArr();
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>

<style lang="scss" scoped>
</style>
