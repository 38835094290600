<!--
 * @Author: mulingyuer
 * @Date: 2021-09-13 10:27:51
 * @LastEditTime: 2021-09-24 15:03:05
 * @LastEditors: aleaner
 * @Description: 企业库筛选
 * @FilePath: \gov\components\enterprise-library\index\Filter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <LayoutFilter :on-reset="onReset" :on-filter="onFilter" labelWidth="90px">
    <el-form class="filter-from" :model="form" :rules="rules" size="medium" ref="ruleForm" label-width="90px">
      <div class="filter-item">
        <el-form-item label="企业名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入企业名称"></el-input>
        </el-form-item>
        <el-form-item label="行业类型：" prop="category_id">
          <el-select v-model="form.category_id" placeholder="请选择行业类型">
            <el-option v-for="op in categoryIdArr" :key="op.value" :label="op.name" :value="op.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上市公司：" prop="is_on_stock">
          <el-select v-model="form.is_on_stock" placeholder="请选择上市公司">
            <el-option v-for="op in isOnStockArr" :key="op.id" :label="op.name" :value="op.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="企业法人：" prop="oper_name">
          <el-input v-model="form.oper_name" placeholder="请输入企业法人"></el-input>
        </el-form-item>
        <el-form-item label="成立时间：" prop="start_date">
          <el-select v-model="form.start_date" placeholder="请选择成立时间">
            <el-option v-for="op in startDateArr" :key="op.start_time" :label="op.name"
              :value="op.start_time">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属商协会：" prop="project_id">
          <el-select v-model="form.project_id" placeholder="请选择所属商协会" @change="onProjectIdChange">
            <el-option v-for="op in projectIdArr" :key="op.project_id" :label="op.name"
              :value="op.project_id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <IntervalInput label="员工人数：" startProp="min_worker_sum" :startValue.sync="form.min_worker_sum"
          endProp="max_worker_sum" :endValue.sync="form.max_worker_sum" />
        <el-form-item label="认证状态：" prop="ident_status">
          <el-select v-model="form.ident_status" placeholder="请选择认证状态">
            <el-option v-for="op in identStatusArr" :key="op.id" :label="op.name" :value="op.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="管理员：" prop="admin_name">
          <el-input v-model="form.admin_name" placeholder="请输入管理员"></el-input>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import IntervalInput from "@/base/components/Form/IntervalInput.vue";
import { BAList } from "../../../api/default";
import {
  categoriesList,
  startDates,
} from "../../../api/enterprise-library/index";
export default {
  props: {
    //筛选回调
    filterChange: {
      required: true,
      type: Function,
    },
    //导出要用的数据
    exportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        name: "", //企业名称
        category_id: -1, // 行业类型
        is_on_stock: -1, // 上市公司
        oper_name: "", // 企业法人
        start_date: -1, // 成立时间
        project_id: "", // 所属商协会
        min_worker_sum: "", //最小员工数
        max_worker_sum: "", //最大员工数
        ident_status: -1, // 认证状态
        admin_name: "", // 管理员
      },
      rules: {},
      //所属商协会选项
      projectIdArr: [],
      //行业类型选项数据
      categoryIdArr: [{ value: -1, name: "全部" }],
      //上市公司选项数据
      isOnStockArr: [
        { name: "全部", id: -1 },
        { name: "否", id: 0 },
        { name: "是", id: 1 },
      ],
      //成立时间选项数据
      startDateArr: [],
      //认证状态选项数据
      identStatusArr: [
        { name: "全部", id: -1 },
        { name: "待认证", id: 0 },
        { name: "已认证", id: 1 },
      ],
    };
  },
  computed: {
    //商协会id
    projectId({ $route }) {
      return $route.query.projectId;
    },
  },
  methods: {
    //重置表单
    onReset() {
      this.$refs["ruleForm"].resetFields();
      this.onFilter();
    },
    //筛选
    onFilter() {
      return this.filterChange(Object.assign({}, this.form));
    },
    //导出
    onExport() {
      const postData = { ...this.exportData, ...this.form };
      // console.log("提交的数据", postData);
      // return orderDocument(postData).catch(() => {});
    },
    //获取商协会选项数据
    getProjectIdArr() {
      BAList()
        .then((res) => {
          const { data } = res;
          this.projectIdArr = data;
        })
        .catch(() => {});
    },
    //获取行业分类选项数据
    getCategoryIdArr() {
      const postData = { project_id: this.form.project_id };
      categoriesList(postData)
        .then((res) => {
          const { data } = res;
          this.categoryIdArr = []
          const names = Array.from(new Set(data.map(d => d.name)))
          this.categoryIdArr.push(...names.map(n => {
            return {
              name: n,
              value: data.find(d => d.name === n)?.value
            }
          }));
        })
        .catch(() => {});
    },
    //获取成立时间选项数据
    getStartDates() {
      const postData = { project_id: this.form.project_id };
      startDates(postData)
        .then((res) => {
          const { data } = res;
          this.startDateArr = []
          this.startDateArr.push(...data);
        })
        .catch(() => {});
    },
    //商协会选项变更
    onProjectIdChange() {
      this.form.start_date = -1; //重置成立时间
      this.form.category_id = -1; //重置行业分类
      this.getStartDates();
      this.getCategoryIdArr();
    },
  },
  created() {
    if (typeof this.projectId !== "undefined") {
      this.form.project_id = this.projectId;
    }
    this.getProjectIdArr();
    this.getCategoryIdArr();
    this.getStartDates();
  },
  components: {
    LayoutFilter,
    IntervalInput,
  },
};
</script>

<style lang="scss" scoped>
</style>
