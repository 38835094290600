/*
 * @Author: mulingyuer
 * @Date: 2021-09-14 17:11:34
 * @LastEditTime: 2021-09-23 14:58:01
 * @LastEditors: aleaner
 * @Description: 会员库列表
 * @FilePath: \gov\api\member-library\index.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";

//会内职务选项数据
export const positionList = data => {
  return api({
    url: "/admin/gov/user/positionList",
    method: "post",
    data
  })
}


//会员库列表数据
export const list = data => {
  return api({
    url: "/admin/gov/user/index",
    method: "post",
    data
  })
}