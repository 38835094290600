/*
 * @Author: mulingyuer
 * @Date: 2021-09-15 16:54:42
 * @LastEditTime: 2021-09-23 15:02:01
 * @LastEditors: aleaner
 * @Description: 活动库
 * @FilePath: \gov\api\activity-library\index.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";


//活动库列表
export const list = data => {
  return api({
    url: "/admin/gov/activity/index",
    method: "post",
    data
  })
}
