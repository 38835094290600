<!--
 * @Author: mulingyuer
 * @Date: 2021-09-14 16:19:33
 * @LastEditTime: 2021-09-26 18:15:20
 * @LastEditors: aleaner
 * @Description: 会员库
 * @FilePath: \gov\views\member-library\index.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="table-page member-library">
    <div class="head">
      <ListFilter :filterChange="onFilter" :exportData="postData" />
    </div>
    <div class="content" v-loading="loading">
      <div class="content-tabs">
        <list-tabs v-model="postData.tab" :tabs="tabArr" @tab-click="handleTabClick" />
      </div>
      <el-table class="thead-light" :data="tbody" stripe style="width: 100%">
        <el-table-column v-for="th in thead" :key="th.prop" :label="th.label" :prop="th.prop"
          :width="th.width" :min-width="th.minWidth" :sortable="th.sortable" :fixed="th.fixed"
          :show-overflow-tooltip="th.tooltip">
          <template slot-scope="{ row }">
            <!-- 操作 -->
            <template v-if="th.prop === 'operate'">
              <el-button type="text" size="mini" @click="$message.info('开发中...')">查看</el-button>
            </template>
            <!-- 头像 -->
            <div v-else-if="th.prop === 'avatar'" class="avatar">
              <img :src="fixImageUrl(row[th.prop] || defaultAvatar)">
            </div>
            <!-- 其他 -->
            <span v-else>
              {{ row[th.prop]|placeholder }}{{th.suffix?th.suffix:""}}
              <el-button v-if="th.click" type="text" class="row-btn" @click="onClick(th.click,row)">
                {{th.click.name}}
              </el-button>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
    </div>
  </div>
</template>

<script>
import ListFilter from "../../components/member-library/index/Filter.vue";
import Pagination from "@/base/components/Default/Pagination";
import { list } from "../../api/member-library/index";
export default {
  data() {
    return {
      loading: false, //加载中
      postData: {
        tab: "formal",
      },
      tabArr: [
        { label: "正式会员", name: "formal" },
        { label: "预备会员", name: "informal" },
      ],
      thead: [
        { label: "ID", prop: "id", minWidth: 100, tooltip: true },
        { label: "头像", prop: "avatar", minWidth: 80 },
        { label: "会员名称", prop: "name", minWidth: 150, tooltip: true },
        { label: "性别", prop: "gender_text", minWidth: 100 },
        {
          label: "会内职务",
          prop: "title_name",
          minWidth: 120,
          tooltip: true,
        },
        { label: "会费状态", prop: "fee_status_text", minWidth: 120 },
        { label: "手机号码", prop: "phone", minWidth: 120 },
        { label: "入会时间", prop: "join_time", minWidth: 180 },
        // { label: "被举报记录", prop: "id", minWidth: 120 },
        {
          label: "所属商协会",
          prop: "project_name",
          minWidth: 120,
          tooltip: true,
        },
        { label: "操作", prop: "operate", minWidth: 120, fixed: "right" },
      ],
      tbody: [],
      pageData: {},
      defaultAvatar:
        window.serverConfig.VUE_APP_ADMINURL +
        "/cyc/images/wxapps/icons/avatar1.png", // 默认头像
    };
  },
  computed: {
    //商协会id
    projectId({ $route }) {
      return $route.query.projectId;
    },
  },
  methods: {
    //获取列表数据
    getList(pageData) {
      this.loading = true;
      return list(pageData || this.postData)
        .then((res) => {
          const { data } = res;
          this.pageData = data;
          this.tbody = data.data;

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //筛选事件
    onFilter(filterObj) {
      Object.assign(this.postData, filterObj, { page: 1 });
      return this.getList();
    },
    //tab切换事件
    handleTabClick() {
      //tab name已经v-model绑定到postData
      Object.assign(this.postData, { page: 1 });
      this.getList();
    },
    //分页变化事件
    changPage(pageObj) {
      Object.assign(this.postData, pageObj);
      this.getList();
    },
  },
  created() {
    if (typeof this.projectId !== "undefined") {
      this.postData.project_id = this.projectId;
    }
    this.getList();
  },
  components: {
    ListFilter,
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
.member-library {
  .content {
    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        vertical-align: top;
      }
    }
  }
}
</style>
