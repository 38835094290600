/*
 * @Author: mulingyuer
 * @Date: 2021-09-15 15:34:36
 * @LastEditTime: 2021-09-29 16:20:32
 * @LastEditors: aleaner
 * @Description:资源库
 * @FilePath: \gov\api\resource-library\index.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";


//分类选项数据
export const categoriesList = data => {
  return api({
    url: "/admin/gov/circleContent/categories",
    method: "post",
    data
  })
}


//资源库列表
export const list = data => {
  return api({
    url: "/admin/gov/circleContent/index",
    method: "post",
    data
  })
}

//全部分类
export const allCategory = data => {
  return api({
    url: "/admin/cyc_circle/category/all",
    method: "post",
    data
  });
};

// 同步资源列表
export const createCircleContents = data => {
  return api({
    url: "/admin/gov/circle_content/createCircleContents",
    method: "post",
    data
  });
};