<!--
 * @Author: mulingyuer
 * @Date: 2021-08-05 17:38:44
 * @LastEditTime: 2021-08-05 18:17:49
 * @LastEditors: mulingyuer
 * @Description: 区间input
 * @FilePath: \agent-admin-vue\src\base\components\Form\IntervalInput.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-form-item :label="label" class="interval-input">
    <el-form-item label-width="0px" :prop="startProp">
      <el-input v-model="startVal"></el-input>
    </el-form-item>
    <span class="attach">{{attach}}</span>
    <el-form-item label-width="0px" :prop="endProp">
      <el-input v-model="endVal"></el-input>
    </el-form-item>
  </el-form-item>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    startProp: {
      type: String,
      required: true,
    },
    //sync绑定
    startValue: {
      required: true,
    },
    endProp: {
      type: String,
      required: true,
    },
    //sync绑定
    endValue: {
      required: true,
    },
    //连接符
    attach: {
      default: "至",
    },
  },
  computed: {
    startVal: {
      get() {
        return this.startValue;
      },
      set(val) {
        this.$emit("update:startValue", val);
      },
    },
    endVal: {
      get() {
        return this.endValue;
      },
      set(val) {
        this.$emit("update:endValue", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.interval-input {
  > .el-form-item__content {
    .el-form-item {
      display: inline-block;
      vertical-align: top;
      ::v-deep.el-form-item__content {
        .el-input input {
          width: 100px;
        }
      }
    }
  }
  .attach {
    margin: 0 12px;
  }
}
</style>