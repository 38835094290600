<template>
  <div>
    <list-layout ref="govTopic" :filter-form="filterForm" :thead="thead"
                 :on-fetch="getList" :on-edit="onEdit" @command="handleOperation"
    >
      <template #filter="{pageData}">
        <topic-list-filter is-gov :filter-form="filterForm"
                           :page="pageData.page"
                           :uploadFilter="ok"  />
      </template>
      <topic-detail-dialog v-model="detailDialog.show" :data="detailDialog.data" />
    </list-layout>
  </div>
</template>

<script>
import ListLayout from "@/base/layout/ListLayout";
import TopicListFilter from "@/modules/community-admin/components/topicAdmin/ListFilter";
import { getGovTopics } from "@/modules/gov/api/topic-library";
import TopicDetailDialog from "@/modules/gov/components/topic-library/TopicDetailDialog";
export default {
  components: {TopicDetailDialog, TopicListFilter, ListLayout},
  data() {
    return {
      filterForm: {
        group_id: -1,
        group_name: '',
        category_id: -1,
        name: "", // 话题名称
        author_name: "", // 创建者名称
        recommend_status: -1, // 推荐状态
        create_start_time: -1, //更新开始时间
        create_end_time: -1, //更新结束时间
        tab: "checked", //当前筛选tab
        page_size: 15, //每页多少条数据
        project_id: this.$store.getters.tenant.id || ''
      },
      thead: [
        {label: "所属商协会", prop: "project_name", minWidth: 100},
        // { label: "封面图", prop: "icon", minWidth: 100 },
        { label: "话题名称", prop: "name", minWidth: 130 },
        // { label: "分类", prop: "category_name", minWidth: 110 },
        { label: "所属小组", prop: "groups_name", minWidth: 150 },
        { label: "创建者", prop: "author_name", minWidth: 110 },
        {
          label: "帖子数",
          prop: "posts_count",
          minWidth: 100,
          sortable: true,
        },
        {
          label: "关注数",
          prop: "collects_count",
          minWidth: 100,
          sortable: true,
        },
        {
          label: "浏览数",
          prop: "view_count",
          minWidth: 100,
          sortable: true,
        },
        {
          label: "创建时间",
          prop: "update_time",
          minWidth: 160,
          sortable: true,
        },
        // {
        //   label: "推荐",
        //   prop: "is_recommend",
        //   minWidth: 60,
        // },
        // {
        //   label: "显示",
        //   prop: "is_show",
        //   minWidth: 60,
        // },
        {
          label: "操作", type: "operation", width: 140, operations: [
            {command: 'edit', text: '详情'},
            {command: 'view', text: '相关帖子'}
            ]
        }
      ],
      detailDialog: {
        show: false,
        data: {}
      }
    }
  },
  methods: {
    handleOperation(e) {
      switch(e.command) {
        case 'view':
          this.$router.push({
            name: "GovTopicPosts",
            params: {
              topic_id: e.row.id
            }
          })
          break
          default:
      }
    },
    onEdit(row) {
      this.detailDialog.data = JSON.parse(JSON.stringify(row))
      this.detailDialog.show = true
    },
    getList(q) {
      return getGovTopics(q)
    },
    // 筛选回调
    ok(e) {
      const pageData = { ...this.filterForm, ...e, page: 1 };
      this.$refs.govTopic.getList(pageData)
    },
  },
}
</script>

<style scoped>

</style>
