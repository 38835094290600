<!--
 * @Author: mulingyuer
 * @Date: 2021-09-13 10:27:51
 * @LastEditTime: 2021-09-29 14:27:27
 * @LastEditors: aleaner
 * @Description: 资讯库筛选
 * @FilePath: \gov\components\news-library\index\Filter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <LayoutFilter :on-reset="onReset" :on-filter="onFilter" labelWidth="90px">
    <el-form class="filter-from" :model="form" :rules="rules" size="medium" ref="ruleForm" label-width="90px">
      <div class="filter-item">
        <DatePeriodPicker label="发布时间：" :start-time.sync="form.post_start_time" start-prop="post_start_time"
          :end-time.sync="form.post_end_time" end-prop="post_end_time" />
      </div>
      <div class="filter-item">
        <el-form-item label="文章标题：" prop="title">
          <el-input v-model="form.title" placeholder="请输入文章标题"></el-input>
        </el-form-item>
        <el-form-item label="来源：" prop="source_name">
          <el-select v-model="form.source_name" placeholder="请选择来源">
            <el-option v-for="op in sourceIdArr" :key="op.value" :label="op.name"
              :value="op.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类：" prop="category_name">
          <el-select v-model="form.category_name" placeholder="请选择分类">
            <el-option v-for="op in categoryIdArr" :key="op.value" :label="op.name"
              :value="op.value">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="所属商协会：" prop="project_id">
          <el-select v-model="form.project_id" placeholder="请选择所属商协会" @change="onProjectIdChange">
            <el-option v-for="op in projectIdArr" :key="op.project_id" :label="op.name"
              :value="op.project_id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { BAList } from "../../../api/default";
import { sourcesList, categoriesList } from "../../../api/news-library/index";
export default {
  props: {
    //筛选回调
    filterChange: {
      required: true,
      type: Function,
    },
    //导出要用的数据
    exportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        post_start_time: -1, //发布开始时间
        post_end_time: -1, //发布结束时间
        title: "", //文章标题
        source_name: "", //来源
        category_name: "", //分类
        project_id: "", //所属商协会
      },
      rules: {},
      //所属商协会选项
      projectIdArr: [],
      //来源选项数据
      sourceIdArr: [{ source_name: "", name: "全部" }],
      //分类选项数据
      categoryIdArr: [{ category_name: "", name: "全部" }],
    };
  },
  computed: {
    //商协会id
    projectId({ $route }) {
      return $route.query.projectId;
    },
  },
  methods: {
    //重置表单
    onReset() {
      this.$refs["ruleForm"].resetFields();
      this.onFilter();
    },
    //筛选
    onFilter() {
      return this.filterChange(Object.assign({}, this.form));
    },
    //导出
    onExport() {
      const postData = { ...this.exportData, ...this.form };
      // console.log("提交的数据", postData);
      // return orderDocument(postData).catch(() => {});
    },
    //获取商协会选项数据
    getProjectIdArr() {
      BAList()
        .then((res) => {
          const { data } = res;
          this.projectIdArr = data;
        })
        .catch(() => {});
    },
    //获取来源选项数据
    getSourcesArr() {
      const postData = { project_id: this.form.project_id };
      sourcesList(postData)
        .then((res) => {
          const { data } = res;
          this.sourceIdArr = []
          this.sourceIdArr.push(...data);
        })
        .catch(() => {});
    },
    //获取分类选项数据
    getCategoryIdArr() {
      const postData = { project_id: this.form.project_id };
      categoriesList(postData)
        .then((res) => {
          const { data } = res;

          const uniqueArr = data.reduce((acc, curr) => {
            // 重复的key取第一次出现的值
            if (!acc.find(el => el.name === curr.name)) {
              acc.push(curr)
            }
            return acc;
          }, []);

          this.categoryIdArr = [...uniqueArr]
        })
        .catch(() => {});
    },
    //商协会选项变更
    onProjectIdChange() {
      this.form.source_name = ""; //重置来源
      this.form.category_name = ""; //重置分类
      this.getSourcesArr();
      this.getCategoryIdArr();
    },
  },
  created() {
    if (typeof this.projectId !== "undefined") {
      this.form.project_id = this.projectId;
    }
    this.getSourcesArr();
    this.getProjectIdArr();
    this.getCategoryIdArr();
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>

<style lang="scss" scoped>
</style>
