/*
 * @Author: mulingyuer
 * @Date: 2021-09-15 15:25:08
 * @LastEditTime: 2021-09-23 14:58:40
 * @LastEditors: aleaner
 * @Description: 默认通用
 * @FilePath: \gov\api\default.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";

//获取商协会选项数据
export const BAList = data => {
  return api({
    url: "/admin/gov/project/all",
    method: "post",
    data
  })
}