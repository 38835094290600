<!--
 * @Author: mulingyuer
 * @Date: 2021-09-15 15:14:15
 * @LastEditTime: 2021-09-29 16:38:07
 * @LastEditors: aleaner
 * @Description: 资源库
 * @FilePath: \gov\views\resource-library\index.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="table-page resource-library">
    <reminder text="同步至资源列表：将资源同步至资源列表的某个分类下，会显示来源于【某某商协会名称】" />
    <el-button class="top-btn" style="margin-bottom: 20px" type="primary" size="small"
      @click="handleSyncResource">同步至资源列表
    </el-button>
    <div class="head">
      <ListFilter :filterChange="onFilter" :exportData="postData" />
    </div>
    <div class="content" v-loading="loading">
      <el-table class="thead-light" :data="tbody" stripe style="width: 100%"
        @selection-change="handleSelectionChange">
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <el-table-column v-for="th in thead" :key="th.prop" :label="th.label" :prop="th.prop"
          :width="th.width" :min-width="th.minWidth" :sortable="th.sortable" :fixed="th.fixed"
          :show-overflow-tooltip="th.tooltip">
          <template slot-scope="{ row }">
            <!-- 操作 -->
            <template v-if="th.prop === 'operate'">
              <el-button type="text" size="mini" @click="$message.info('开发中...')">查看</el-button>
            </template>
            <!-- 其他 -->
            <span v-else>
              {{ row[th.prop]|placeholder }}{{th.suffix?th.suffix:""}}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
      <!-- 同步资源列表弹窗 -->
      <SyncResourceDialog v-model="openSyncResourceDialog" :resourceIds="resource_ids" :complete="getList" />
    </div>
  </div>
</template>

<script>
import ListFilter from "../../components/resource-library/index/Filter.vue";
import SyncResourceDialog from "../../components/resource-library/index/SyncResourceDialog.vue";
import Pagination from "@/base/components/Default/Pagination";
import Reminder from "@/modules/common/components/Reminder.vue";
import { list } from "../../api/resource-library/index";
export default {
  data() {
    return {
      loading: false, //加载中
      postData: {},
      thead: [
        { label: "ID", prop: "id", minWidth: 120 },
        { label: "内容", prop: "content", minWidth: 180, tooltip: true },
        { label: "内容类型", prop: "content_type", minWidth: 120 },
        { label: "内容分类", prop: "category_name", minWidth: 120 },
        { label: "发布人", prop: "author_name", minWidth: 120 },
        { label: "内容付费", prop: "pay_text", minWidth: 120 },
        { label: "付费人数", prop: "pay_count", minWidth: 120 },
        //  { label: "内容收入", prop: "", minWidth: 100},
        { label: "发布时间", prop: "post_time", minWidth: 180 },
        {
          label: "所属商协会",
          prop: "project_name",
          minWidth: 120,
          tooltip: true,
        },
        { label: "操作", prop: "operate", minWidth: 120, fixed: "right" },
      ],
      tbody: [],
      pageData: {},
      resource_ids: [], // 文章id数组
      openSyncResourceDialog: false, // 是否显示同步资源列表弹窗
    };
  },
  computed: {
    //商协会id
    projectId({ $route }) {
      return $route.query.projectId;
    },
  },
  methods: {
    //获取列表数据
    getList(pageData) {
      this.loading = true;
      return list(pageData || this.postData)
        .then((res) => {
          const { data } = res;
          this.pageData = data;
          this.tbody = data.data;

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.resource_ids = [];
      } else {
        this.resource_ids = [];
        // 筛选id
        selection.forEach((item) => {
          this.resource_ids.push(item.id);
        });
      }
    },
    // 同步资源列表操作
    handleSyncResource() {
      if (this.resource_ids.length) {
        this.openSyncResourceDialog = true;
      } else {
        this.$message.info("请选择数据项");
      }
    },
    //筛选事件
    onFilter(filterObj) {
      Object.assign(this.postData, filterObj, { page: 1 });
      return this.getList();
    },
    //分页变化事件
    changPage(pageObj) {
      Object.assign(this.postData, pageObj);
      this.getList();
    },
  },
  created() {
    if (typeof this.projectId !== "undefined") {
      this.postData.project_id = this.projectId;
    }
    this.getList();
  },
  components: {
    ListFilter,
    Pagination,
    Reminder,
    SyncResourceDialog,
  },
};
</script>

<style lang="scss" scoped>
</style>
