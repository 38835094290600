<!--
 * @Author: mulingyuer
 * @Date: 2021-09-13 10:27:51
 * @LastEditTime: 2021-09-15 17:08:31
 * @LastEditors: mulingyuer
 * @Description: 活动库筛选
 * @FilePath: \gov-admin-vue\src\modules\data-list\components\activity-library\index\Filter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <LayoutFilter :on-reset="onReset" :on-filter="onFilter" labelWidth="90px">
    <el-form class="filter-from" :model="form" :rules="rules" size="medium" ref="ruleForm" label-width="90px">
      <div class="filter-item">
        <DatePeriodPicker label="活动时间：" :start-time.sync="form.start_time" start-prop="start_time"
          :end-time.sync="form.end_time" end-prop="end_time" />
      </div>
      <div class="filter-item">
        <el-form-item label="活动名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入活动名称"></el-input>
        </el-form-item>
        <el-form-item label="活动类型：" prop="type">
          <el-select v-model="form.type" placeholder="请选择活动类型">
            <el-option v-for="op in typeArr" :key="op.id" :label="op.name" :value="op.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报名类型：" prop="sign_type">
          <el-select v-model="form.sign_type" placeholder="请选择报名类型">
            <el-option v-for="op in signTypeArr" :key="op.id" :label="op.name" :value="op.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="活动状态：" prop="status">
          <el-select v-model="form.status" placeholder="请选择活动状态">
            <el-option v-for="op in statusArr" :key="op.id" :label="op.name" :value="op.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属商协会：" prop="project_id">
          <el-select v-model="form.project_id" placeholder="请选择所属商协会" @change="onProjectIdChange">
            <el-option v-for="op in projectIdArr" :key="op.project_id" :label="op.name"
              :value="op.project_id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { BAList } from "../../../api/default";
export default {
  props: {
    //筛选回调
    filterChange: {
      required: true,
      type: Function,
    },
    //导出要用的数据
    exportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        start_time: -1, //活动开始时间
        end_time: -1, //活动结束时间
        name: "", // 活动名称
        type: -1, // 活动类型
        sign_type: -1, // 报名类型
        status: -1, // 活动状态
        project_id: '' // 所属商协会
      },
      rules: {},
      //所属商协会选项
      projectIdArr: [],
      //活动类型选项数据
      typeArr: [
        { name: "全部", id: -1 },
        { name: "线上活动", id: 0 },
        { name: "线下活动", id: 1 },
      ],
      //报名类型选项数据
      signTypeArr: [
        { name: "全部", id: -1 },
        { name: "免费报名", id: 0 },
        { name: "保证金报名", id: 1 },
        { name: "付费报名", id: 2 },
      ],
      //活动状态选项数据
      statusArr: [
        { name: "全部", id: -1 },
        { name: "未开始", id: 0 },
        { name: "进行中", id: 1 },
        { name: "已结束", id: 2 },
        { name: "已取消", id: 3 },
      ],
    };
  },
  computed: {
    //商协会id
    projectId({ $route }) {
      return $route.query.projectId;
    },
  },
  methods: {
    //重置表单
    onReset() {
      this.$refs["ruleForm"].resetFields();
      this.onFilter();
    },
    //筛选
    onFilter() {
      return this.filterChange(Object.assign({}, this.form));
    },
    //导出
    onExport() {
      const postData = { ...this.exportData, ...this.form };
      // console.log("提交的数据", postData);
      // return orderDocument(postData).catch(() => {});
    },
    //获取商协会选项数据
    getProjectIdArr() {
      BAList()
        .then((res) => {
          const { data } = res;
          this.projectIdArr = data;
        })
        .catch(() => {});
    },
    //商协会选项变更
    onProjectIdChange() {},
  },
  created() {
    if (typeof this.projectId !== "undefined") {
      this.form.project_id = this.projectId;
    }
    this.getProjectIdArr();
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>

<style lang="scss" scoped>
</style>
