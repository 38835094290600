<!--
 * @Author: mulingyuer
 * @Date: 2021-09-14 17:56:06
 * @LastEditTime: 2021-09-29 15:58:18
 * @LastEditors: aleaner
 * @Description: 资讯库
 * @FilePath: \gov\views\news-library\index.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="table-page news-library">
    <reminder text="同步至文章列表：将资讯同步至文章列表的某个分类下，会显示来源于【某某商协会名称】" />
    <el-button class="top-btn" style="margin-bottom: 20px" type="primary" size="small"
      @click="handleSyncArticle">同步至文章列表
    </el-button>
    <div class="head">
      <ListFilter :filterChange="onFilter" :exportData="postData" />
    </div>
    <div class="content" v-loading="loading">
      <el-table class="thead-light" :data="tbody" stripe style="width: 100%"
        @selection-change="handleSelectionChange">
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <el-table-column v-for="th in thead" :key="th.prop" :label="th.label" :prop="th.prop"
          :width="th.width" :min-width="th.minWidth" :sortable="th.sortable" :fixed="th.fixed"
          :show-overflow-tooltip="th.tooltip" :type="th.type">
          <template slot-scope="{ row }">
            <!-- 操作 -->
            <template v-if="th.prop === 'operate'">
              <el-button type="text" size="mini" @click="$message.info('开发中...')">查看</el-button>
            </template>
            <!-- 其他 -->
            <span v-else>
              {{ row[th.prop]|placeholder }}{{th.suffix?th.suffix:""}}
              <el-button v-if="th.click" type="text" class="row-btn" @click="onClick(th.click,row)">
                {{th.click.name}}
              </el-button>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
    </div>
    <!-- 同步文章列表弹窗 -->
    <SyncArticleDialog v-model="openSyncArticleDialog" :articleIds="article_ids" :complete="getList" />
  </div>
</template>

<script>
import ListFilter from "../../components/news-library/index/Filter.vue";
import SyncArticleDialog from "../../components/news-library/index/SyncArticleDialog.vue";
import Pagination from "@/base/components/Default/Pagination";
import { list } from "../../api/news-library/index";
import Reminder from "@/modules/common/components/Reminder.vue";
export default {
  data() {
    return {
      loading: false, //加载中
      postData: {},
      thead: [
        { label: "ID", prop: "id", minWidth: 100, tooltip: true },
        { label: "文章标题", prop: "title", minWidth: 180, tooltip: true },
        { label: "来源", prop: "source_name", minWidth: 100 },
        { label: "分类", prop: "category_name", minWidth: 120, tooltip: true },
        { label: "状态", prop: "status_text", minWidth: 120 },
        { label: "评论量", prop: "comments_count", minWidth: 120 },
        { label: "点赞量", prop: "likes_count", minWidth: 120 },
        { label: "收藏量", prop: "collects_count", minWidth: 120 },
        { label: "浏览量", prop: "views_count", minWidth: 120 },
        { label: "发布时间", prop: "post_time", minWidth: 180 },
        { label: "所属商协会", prop: "project_name", minWidth: 120 },
        { label: "操作", prop: "operate", minWidth: 120, fixed: "right" },
      ],
      tbody: [],
      pageData: {},
      article_ids: [], // 文章id数组
      openSyncArticleDialog: false, // 是否显示同步文章列表弹窗
    };
  },
  computed: {
    //商协会id
    projectId({ $route }) {
      return $route.query.projectId;
    },
  },
  methods: {
    //获取列表数据
    getList(pageData) {
      this.loading = true;
      return list(pageData || this.postData)
        .then((res) => {
          const { data } = res;
          this.pageData = data;
          this.tbody = data.data;

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.article_ids = [];
      } else {
        this.article_ids = [];
        // 筛选id
        selection.forEach((item) => {
          this.article_ids.push(item.id);
        });
      }
    },
    // 同步文章列表操作
    handleSyncArticle() {
      if (this.article_ids.length) {
        this.openSyncArticleDialog = true;
      } else {
        this.$message.info("请选择数据项");
      }
    },
    //筛选事件
    onFilter(filterObj) {
      Object.assign(this.postData, filterObj, { page: 1 });
      return this.getList();
    },
    //分页变化事件
    changPage(pageObj) {
      Object.assign(this.postData, pageObj);
      this.getList();
    },
  },
  created() {
    if (typeof this.projectId !== "undefined") {
      this.postData.project_id = this.projectId;
    }
    this.getList();
  },
  components: {
    ListFilter,
    Pagination,
    Reminder,
    SyncArticleDialog,
  },
};
</script>

<style lang="scss" scoped>
</style>